.StreamContainer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.BackgoundContainer {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 100%;
  height: 100%;
  position: fixed;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(250.84deg, #222222 -14.04%, #000000 109.3%);
    opacity: 0.65;
  }
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
}

.login-box {
  position: relative;
  width: 100%;
  max-width: 560px;

  display: table;
  margin: 0 auto;

  // top: 30px;

  padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  padding-bottom: 40px;

  border-radius: 10px;
  background-color: $background-color;
  z-index: 5;

  &::before {
    position: absolute;
    top: 0;
    left: -39px;
    bottom: -22px;
    right: 0;
    content: "";
    background: rgba(0, 0, 0, 0.2);
    filter: blur(40px);
    z-index: -1;
  }

  &::after {
    content: "";
    opacity: 0.4;
    background-image: url(../img/background-texture.png);
    background-repeat: repeat;
    background-size: 50%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    border-radius: 10px;
  }
}

.login-logo-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.signin-textstyle {
  font-size: 2.5rem;
  line-height: 1.2;
  letter-spacing: 0.055em;
  font-weight: 700;
  color: $text-color;
  padding-top: 15px;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;

  &.black {
    color: $background-color;
  }
}
@media (max-width: 767.98px) {
  .signin-textstyle {
    display: flex;
    margin: auto;
    font-size: 28px;
  }
}

#loginwithGoogle {
  width: 100%;
  height: 50px;

  background-color: transparent;
  border: 1px solid $primary-color;

  margin-bottom: 10px;

  border-radius: 3px;

  color: $tertiary-color;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  vertical-align: middle;

  &:hover {
    background-color: rgba($tertiary-color, 0.1);
  }
}

.or-text {
  margin: 10px 0 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1;
  color: $tertiary-color;
  text-align: center;
}

img.icon {
  margin-right: 10px;
}

.textfield-1 {
  width: 100%;
  position: relative;
  border: 1px solid $primary-color;
  border-radius: 3px;
  background-color: transparent !important;
  background: transparent !important;
  padding: 16px 26px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1;
  text-align: left;
  color: $primary-color;
  padding-right: 3rem !important;

  &::placeholder,
  &::after,
  &::before {
    color: $tertiary-color;
  }

  &:focus {
    font-size: 1.125rem;
    border: 1px solid $secondary-color;
    outline: none;
    background-color: transparent;
  }

  &.is-invalid {
    border-color: $error-color;
  }
}

.show-password-toggle {
  position: absolute;
  right: 0;

  span {
    background-color: transparent;
    border: none;
    padding: 18px 20px;
    font-size: 1.25rem;
    cursor: pointer;
    color: #abafb4;
  }
}

.forgot-password-style {
  color: $link-color-1;
  display: inline-block;
  margin-right: 10px;
  text-align: right;
}
.w-half {
  width: 16% !important;
  margin: auto;
  margin-bottom: 2rem !important;
}
.btn-login {
  width: 10rem;
  border-radius: 2rem;
  padding: 0.5rem !important;
  @media (max-width: 768px) {
   width: 7rem;
}
}
.btn-custom-form {
  background-color: $button-default;
  color: $text-color;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 700;
  font-family: "Europa-Bold", Josefin Sans, sans-serif;
  letter-spacing: 0.1rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7em 0;

  &:hover {
    background-color: $button-hover;
    color: #fff;
  }

  &:active,
  &:focus {
    background-color: $button-hover;
    color: #fff;
  }
}

.agreement-text-style {
  vertical-align: middle;
  font-size: 13px;
  line-height: 1.4;
  color: $tertiary-color;
  padding-left: 5px;
  display: flex;
  align-items: center;

  a {
    display: contents;
    text-decoration: underline;
    color: $link-color-4;
  }
}

.linkDefalut {
  color: $link-color-3;
  text-decoration: underline;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}

.linkSuccessGreen {
  color: $primary-color;
  text-decoration: underline;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}

.register-text-style {
  font-size: 1.125rem;
  font-weight: 400;
  text-align: center;
  color: #fff;

  a {
    padding-left: 5px;
    display: inline-block;
  }
}

.navbar {
  position: relative;
}

.brand {
  position: absolute;
  left: 50%;
  margin-left: -50px !important;
  /* 50% of your logo width */
  display: block;
}

.see_more {
  width: 5rem;
}
@media (min-width: 992px) {
  .navbar-custom-toggler {
    display: none;
  }

  .navbar-custom-brand {
    display: none;
  }
}

.item:active {
  color: #fff;
  text-decoration: none;
  background-color: #262727;
}

.btn-next {
  background-color: $primary-color;
  color: $text-color;
  letter-spacing: 0.1em;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 500;
  padding: 14px 10px 12px;
  text-transform: uppercase;

  &:active {
    background-color: $button-hover !important;
    box-shadow: none !important;
  }

  &:focus {
    background-color: $button-hover !important;
    box-shadow: none !important;
  }

  &:hover {
    background-color: $button-hover;
  }
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 33px;
  letter-spacing: 0.1em;
  color: #d6d2bc;
  text-transform: uppercase;
}

.header-section {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.header-logo-container {
  position: relative;
  margin: 30px 0 0;

  img {
    max-width: 100%;
  }
}

.user-profile-container {
  position: relative;
  float: right;
  margin-top: 30px;

  .navbar-nav {
    flex-direction: row;

    .cashout {
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        height: 40px;
        width: auto;
        margin-right: 1em;
      }
    }
  }
}

.userpicture-style {
  border: 1px solid #ffffff;
  border-radius: 100%;
  margin-right: 10px;
  width: 30px;
}

.username-style {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  color: #fff;
}

.useremail-style {
  font-size: 0.5625rem;
  color: #d6d6d6;
  margin-bottom: 0;
}

.user-arrow-icon {
  font-size: 26px;
}

.user-dropdown-style {
  position: absolute !important;
  background-color: $primary-color;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  .dropdown-item {
    padding: 6px 15px;
    border-radius: 5px;

    &.active {
      background-color: $secondary-color;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      background-color: $secondary-color;
      color: #fff;
    }
  }
}

.nav-link-container {
  a {
    text-decoration: none;
  }
}

.payment {
  padding-top: 220px;
}
.bgblue {
  background-color: #84c142 !important;
  color: white;
}
.filter-button {
  font-family: 'Europa-Bold';
  color: #fff;
  width: 100%;
  height: 2.5rem;
  background-color: #29262e;
  outline: none;
  border: none;
  border-radius: 25px;
  transition: 0.5s all ease;
  min-width: 7rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.filter-button:hover {
  background-color: #84c142 !important;
  color: white;
}
.popup-box {
  padding: 50px 70px 30px;
  background-color: rgba(#333333, 0.7);
  box-shadow: -9px 15px 19px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-bottom: 70px;
  color: #fff;
}
.popup {
  button {
    background-color: transparent;
    border: none;
    color: #84c142;
    float: right;
    margin-top: 1rem;
    margin-right: 1rem;
    z-index: 1;
  }
}
.popup-title {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: $tertiary-color;
}

.card-provider-img {
  max-width: 125px;
  width: calc(33.33% - 12px);

  border-radius: 3px;
  padding: 15px 10px;
  background-color: #fff;
  margin: 0 6px;
  text-align: center;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    max-width: 100%;
    max-height: 36px;
  }
}

.thank-you-text {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.2;
  letter-spacing: 0.05em;
  color: $primary-color;
}
.payment-text {
  color: rgb(222, 158, 0) !important;
  font-size: 4rem !important;
  font-weight: 600 !important;
}
.description-text {
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.55;

  text-align: center;
  letter-spacing: 0.01em;
}

.loading-container {
  padding: 100px;
}

.payment-content {
  margin-bottom: 3rem;

  .back-button {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1;
    color: $text-color;

    display: flex;
    align-items: center;

    margin-bottom: 2rem;
    cursor: pointer;

    .back-button-icon {
      margin-right: 5px;
      margin-left: -10px;
      font-size: 3rem;
    }
  }

  .pre-headline {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.375;
    letter-spacing: 0.1em;

    color: $text-color;
    margin-bottom: 2rem;

    display: block;
  }

  .headline {
    font-style: normal;
    font-weight: bold;
    font-size: 2.1875rem;
    line-height: 1.25;

    letter-spacing: 0.1em;
    color: $text-color;

    margin-bottom: 1.75rem;
  }

  .sub-headline {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.5;
    text-transform: none;

    letter-spacing: 0.1em;
    color: $text-color;

    margin-bottom: 1rem;
  }

  div {
    font-size: 1.125rem;
    line-height: 1.5;
    letter-spacing: 0.01em;
    font-weight: 400;
    white-space: pre-line;
    color: $text-color;
  }
}
.footer-container {
  width: 100%;
  background-color: #001A31;
  color: white;
  .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .currencyworks {
    width: 61%;
  }
  .enderby {
    width: 80%;
  }
  h1 {
    font-size: 14px;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: 2.8px;
  }
  p {
    font-size: 16px;
    font-family: "Europa-Regular", Josefin Sans, sans-serif !important;
    margin: 0px;
  }
  .link_section {
    div {
      display: flex;

      .ul_left {
        padding: 0rem;
        li {
          list-style-type: none;
          padding: 0.2rem 0rem;
          a {
            color: white;
          }
        }
      }
      .ul_right {
        padding-left: 1.5rem;
        li {
          list-style-type: none;
          padding: 0.2rem 0rem;

          a {
            color: white;
          }
        }
      }
    }
  }
  .links {
    a {
      color: #ffffff;
    }
    :hover {
      color: #478ac9;
    }
  }
}
@media (max-width: 767.98px) {
  .footer-container {
    .container {
      text-align: center;
    }
    .link_section {
      h1 {
        margin-top: 0.8rem;
      }
      div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .ul_left {
          margin-bottom: 0rem;
        }
        .ul_right {
          padding-left: 0rem;
        }
      }
    }
  }
}

.empty-state-container {
  padding: 50px 80px 30px;
  border-radius: 20px;
  border: 1px dashed rgba(0, 0, 0, 0.3);

  text-align: center;
  color: $text-color;

  .empty-state-style {
    filter: invert(11%) sepia(14%) saturate(3313%) hue-rotate(128deg)
      brightness(91%) contrast(103%);
    margin-bottom: 20px;
  }

  h2 {
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 1.3;

    letter-spacing: 0.05em;

    color: $text-color;
    margin-bottom: 5px;
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: $text-color;
    letter-spacing: 0.01em;
  }
}

.empty-state-container.light {
  border: 1px dashed rgba($primary-color, 0.3);

  color: $primary-color;

  .empty-state-style {
    // filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(295deg) brightness(107%) contrast(101%); White
    filter: invert(96%) sepia(9%) saturate(440%) hue-rotate(3deg)
      brightness(87%) contrast(92%);
  }

  h2 {
    color: $primary-color;
  }

  p {
    color: $primary-color;
  }
}
.coinbase-button {
  height: 0;
  visibility: hidden;
}
.spinner {
  margin: 100px auto 0;
  width: 120px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 0 5px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner.light > div {
  background-color: rgba(255, 255, 255, 0.3);
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.modal {
  background: rgba(255, 255, 255, 0.2);
}

.claim-modal {
  .modal-header {
    border: none;
    color: white;
    padding-left: 0;
    padding-right: 0;
  }

  .modal-content {
    background: transparent;
    border: none;
  }

  .modal-body {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
}

.claiming-modal {
  max-width: 100%;
  height: 100%;
  margin: 0;

  .img-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }
}

.claimed-modal .modal-content {
  background-color: rgba($text-color, 1);
  padding: 50px 0 40px;
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
}

/* COUROUSAL DESIGN CHANGES */
.carousel-root {
  display: flex;
  flex-direction: column;

  .carousel {
    order: 1;

    .thumbs-wrapper {
      margin: 0;

      img,
      video {
        margin: 0;
      }

      .thumbs {
        padding: 0;
        margin: 0 auto;
        margin-bottom: 15px;
        display: table;
        max-width: 1140px;
      }

      .thumb {
        border: 0;
        opacity: 0.3;

        img {
          box-shadow: none;
          margin: 0;
        }

        &.selected {
          opacity: 1;
        }
      }
    }

    .slide {
      transform: translateY(20px);
      position: relative;
      z-index: 0;

      img,
      video {
        box-shadow: none;
      }

      &.selected {
        transform: translateY(0);
        position: relative;
        z-index: 1;
        margin-bottom: 20px;

        img {
          box-shadow: 0 20px 20px 10px rgba(0, 0, 0, 0.2);
        }
      }
    }

    .control-arrow {
      &.control-prev {
        &::before {
          content: "";
          border: 0;
        }
      }
    }
  }

  .carousel-slider {
    order: 2;
  }
}

/*========================================================
  HERO SECTIONS
==========================================================*/

.hero-section {
  position: relative;
  height: 768px;
  min-height: 100vh;

  padding-top: 150px;

  display: flex;
  align-items: center;

  .hero-media-container {
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;

      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;

      object-fit: cover;
    }
  }

  .hero-content {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .catergory-text {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.375;
    letter-spacing: 0.1em;
    color: $text-color;

    display: block;
    margin-bottom: 0.875rem;
  }

  h1 {
    font-weight: bold;
    font-size: 5rem;
    line-height: 1;
    letter-spacing: 0.1em;
    color: $text-color;

    margin-bottom: 0.5rem;
  }

  .year-and-starring {
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.55;
    letter-spacing: 0.01em;
    color: $text-color;

    margin-bottom: 1rem;

    .year-released {
      padding-right: 15px;
      font-weight: 600;
    }

    .starring {
      padding-left: 15px;

      b {
        font-weight: 400;
      }
    }
  }

  h3 {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.55;
    letter-spacing: 0.1em;
    color: $text-color;

    margin-bottom: 0.25rem;
  }

  p {
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.55;
    letter-spacing: 0.01em;
    color: $text-color;

    margin-bottom: 1.875rem;
  }

  .play-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 266px;
    height: 266px;

    background: rgba(132, 193, 66, 0.35);
    border-radius: 100%;

    text-align: center;

    transition: all 0.1s ease-in-out;

    &:hover {
      background: rgba(132, 193, 66, 0.6);
    }

    img {
      width: 37%;
      max-width: 100%;
      margin-left: 15px;
    }
  }
}

.home-grid {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 27.07%,
    rgba(35, 34, 35, 0) 100%
  );
  background-color: rgba(132, 193, 66, 0.3);

  padding: 6.25rem 0;
}

.headline-with-logo {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.375;
  text-align: center;
  letter-spacing: 0.1em;
  color: $text-color;

  margin-bottom: 6.25rem;

  img {
    width: 140px;
    max-width: 100%;
    display: inline;

    margin-left: 0.25rem;
  }
}

.film-thumbnail {
  width: 300px;
  max-width: 100%;
  position: relative;
  display: table;

  margin: 0 auto 2.5rem;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    transition: all 0.1s ease-in-out;
  }

  &::before {
    background: rgba(0, 0, 0, 0.2);
    filter: blur(20px);
  }

  &::after {
    background-image: url(./../img/icon-video.svg);
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
  }

  &:hover {
    &::before {
      background: rgba(0, 0, 0, 0.3);
    }

    &::after {
      transform: scale(1.05);
      opacity: 0.6;
    }
  }

  img {
    max-width: 100%;
  }
}

/* =========  CARDS & PACKS STYLE ========== */

.page-title {
  color: $text-color;
  font-size: 1.75rem;
}

.navigation-bar {
  position: relative;
  margin-top: 30px;

  .nav-item {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
    a {
      color: #fff !important;
    }
  }
}

/* MEDIA QUERIES */

@media (max-width: 991.98px) {
  .login-container {
    height: 100%;

    .login-logo-container {
      padding-bottom: 2rem;
      img {
        max-width: 50%;
      }
    }
  }

  .header-logo-container {
    margin-left: 0;
    margin-right: 0;
  }

  .payment {
    padding-top: 240px;
  }

  .navigation-bar {
    margin-bottom: 1rem;
    .nav-item {
      &:first-child {
        padding-left: 0;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .hero-section {
    .catergory-text {
      font-size: 1.25rem;
      line-height: 1.375;
    }

    h1 {
      font-size: 4rem;
      line-height: 1;
    }

    .year-and-starring {
      font-size: 1rem;
      line-height: 1.5;

      .year-released {
        padding-right: 10px;
      }

      .starring {
        padding-left: 10px;
      }
    }

    h3 {
      font-size: 1rem;
      line-height: 1.5;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
    }

    .play-button-container {
      width: 80px;
      height: 80px;

      img {
        width: 37%;
        margin-left: 10px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .login-box {
    &::before {
      display: none;
    }
  }

  /* HERO SECTION RESPONSIVE 767.98 MAX*/
  .home-grid {
    padding: 4rem 0 3rem;
  }

  .headline-with-logo {
    margin-bottom: 4rem;
  }
}

@media (max-width: 575.98px) {
  .header-logo-container {
    img {
      max-width: 60%;
    }
  }

  .navigation-bar .nav-item {
    padding: 0 6px;
  }

  .login-box {
    padding-left: 40px;
    padding-right: 40px;
    display: block;
  }

  .popup-box {
    padding-left: 25px;
    padding-right: 25px;
  }

  /* HERO SECTION RESPONSIVE 575.98px max*/

  .hero-section {
    padding-top: 212.83px;

    height: 100vh;
    min-height: 100vh;

    align-items: flex-end;

    .hero-content {
      margin-top: 30px;
    }

    .catergory-text {
      font-size: 1rem;
      line-height: 1.375;
      white-space: normal;
    }

    h1 {
      font-size: 3rem;
      line-height: 1;
      white-space: normal;
    }

    .year-and-starring {
      font-size: 1rem;
      line-height: 1.5;
      white-space: normal;

      .year-released {
        padding-right: 5px;
      }

      .starring {
        padding-left: 5px;
      }
    }

    h3 {
      font-size: 1.25rem;
      line-height: 1.3;
      white-space: normal;
    }

    p {
      font-size: 1rem;
      line-height: 1.3;
      white-space: normal;
    }

    .hero-button {
      width: 100%;
    }
  }
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 900px;
  width: auto;
}

// Changes
.beta-version {
  color: #abafb4;
  position: fixed;
  top: 5px;
  right: 15px;
  z-index: 2;
}

.seemore_btn {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  z-index: 2;
  width: 100%;
  animation: mymove 5s infinite;
  font-family: 'Europa-Bold';
}
@keyframes mymove {
  0% {
    bottom: 2rem;
  }
  25% {
    bottom: 0.5rem;
  }
  50% {
    bottom: 0.5rem;
  }
  100% {
    bottom: 2rem;
  }
}
.seemore_btn button {
  color: #fff;
  background-color: #29262e;
  outline: none;
  border: none;
  border-radius: 2rem;
  width: 8rem;
  height: 2rem;
  transition: 0.5s all ease;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.seemore_btn button {
  display: flex;
  align-items: center;
  svg {
    width: 0.8rem;
    margin-left: 0.4rem;
    path {
      fill: #84c142;
    }
  }
}
// .seemore_btn button:hover {
//   background-color: #84c142 !important;
//   color: white;
//   svg {
//     width: 0.8rem;
//     margin-left: 0.4rem;
//     path{
//      fill: #ffffff;
//     }
//   }
// }

.transaction-image {
  height: 180px;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}

.textfield-1 {
  &.PhoneInput {
    input {
      border: none;
      background: none;
      color: #fff;
      outline: none;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.filters {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Filter h1 {
  color: #d6d2bc;
}

.btns button {
  color: #fff;
  background-color: #29262e;
  outline: none;
  border: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  transition: 0.5s all ease;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.btns button {
  img {
    width: 1rem;
  }
}
.btns button:hover {
  background-color: #84c142 !important;
  color: white;
}

.Filter h1 {
  color: white;
  font-size: 1.5rem;
}

.editProfile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // .seletorAvator{
  //     display: none;
  // }
  label {
  }

  .inputFile {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 20px;
    position: relative;
    left: 60px;
    bottom: 30px;
    z-index: 10;
    background-color: $primary-color;
    color: #ffffff;
    box-shadow: 0px 4px 9px #00000029;
    cursor: pointer;
    input {
      display: none;
    }
  }
}
