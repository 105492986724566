@keyframes check {
    0% {
        height: 0;
        width: 0;
    }
    25% {
        height: 0;
        width: 10px;
    }
    50% {
        height: 20px;
        width: 10px;
    }
}
.checkbox {
    background-color: $background-color;
    display: inline-block;
    height: 28px;
    margin: 0 0.25em;
    width: 28px;
    border-radius: 3px;
    border: 1px solid $primary-color;
    float: left;
    margin-right: 10px;
}
.checkbox span {
    display: block;
    height: 30px;
    position: relative;
    width: 30px;
    padding: 0;
}
.checkbox span:after {
    -moz-transform: scaleX(-1) rotate(135deg);
    -ms-transform: scaleX(-1) rotate(135deg);
    -webkit-transform: scaleX(-1) rotate(135deg);
    transform: scaleX(-1) rotate(135deg);
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    border-right: 4px solid $background-color;
    border-top: 4px solid $background-color;
    content: "";
    display: block;
    height: 20px;
    left: 3px;
    position: absolute;
    top: 15px;
    width: 10px;
}
.checkbox span:hover:after {
    // border-color: rgba($tertiary-color, 0.2);
}
.checkbox input {
    display: none;
}
.checkbox input:checked + span:after {
    -webkit-animation: check 0.8s;
    -moz-animation: check 0.8s;
    -o-animation: check 0.8s;
    animation: check 0.8s;
    border-color: #555;
}
.checkbox input:checked + .default:after {
    border-color: $primary-color;
}
