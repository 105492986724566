$font-size-base: 0.96rem;

$primary-color: #84c142;
$secondary-color: #437d2c;
$tertiary-color: #d9efb6;

$background-color: #050f19;
$text-color: #ffffff;

$link-color-1: $tertiary-color;
$link-color-2: $primary-color;
$link-color-3: #de9e00;
$link-color-4: #22b2fb;

$button-default: $primary-color;
$button-hover: $secondary-color;

// $button-2-default: #f86e3a;
// $button-2-hover: $secondary-color;

$error-color: #dc3545;
$success-color: #155724;

@font-face {
  font-family: 'Europa-Regular';
  src: url('Europa/europa-regular.eot');
  src: url('Europa/europa-regular.eot?#iefix')
      format('embedded-opentype'),
    url('Europa/europa-regular.woff2') format('woff2'),
    url('Europa/europa-regular.woff') format('woff'),
    url('Europa/europa-regular.ttf') format('truetype'),
    url('Europa/europa-regular.svg#geomanistregular')
      format('svg');
  font-weight: 400;
  font-style: normal;
}





@font-face {
  font-family: 'Europa-Bold';
  src: url('Europa/europa-bold.eot');
  src: url('Europa/europa-bold.eot?#iefix')
      format('embedded-opentype'),
    url('Europa/europa-bold.woff2') format('woff2'),
    url('Europa/europa-bold.woff') format('woff'),
    url('Europa/europa-bold.ttf') format('truetype'),
    url('Europa/europa-bold.svg#geomanistbold') format('svg');
  font-weight: 700;
  font-style: normal;
}

// Required
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap';
@import './customCheckMark.scss';

//@import Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&display=swap');

//@import 'style';
@import './custom.scss';
body {
  background: $background-color;
  font-family: Europa-Regular, 'Josefin Sans', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Europa-Bold, 'Josefin Sans', sans-serif;
   
  
}
h2{
 font-size: 14px;   
}
a {
  color: $link-color-1;

  &:hover {
    text-decoration: none;
    color: $primary-color;
  }
}
